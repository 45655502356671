<template>
  <div class="new-tip">
    <IncentivesTipSent v-if="status === 'success'" :autoclose="3000" @autoclosed="$emit('close')" />
    <div v-else>
      <div class="block-input" tcenter bold>
        <span>{{ $locale["object"]["incentive"]["send-tip-title"] }}</span>
        <span v-html="` @${creator.user.user}`"></span>
      </div>
      <div class="block-input">
        <div class="tip-suggestions" :key="sgtkey">
          <SubscribePriceBy :subscription="{ status: 'on' }" label="5" name="sgtip" @checked="tipMount = 5" />
          <SubscribePriceBy :subscription="{ status: 'on' }" label="20" name="sgtip" @checked="tipMount = 20" />
          <SubscribePriceBy :subscription="{ status: 'on' }" label="50" name="sgtip" @checked="tipMount = 50" />
          <SubscribePriceBy :subscription="{ status: 'on' }" label="100" name="sgtip" @checked="tipMount = 100" />
          <SubscribePriceBy :subscription="{ status: 'on' }" label="150" name="sgtip" @checked="tipMount = 150" />
          <SubscribePriceBy :subscription="{ status: 'on' }" label="200" name="sgtip" @checked="tipMount = 200" />
        </div>
      </div>

      <div class="IncentivesTipOptions" dpadding>
        <div class="block-input">
          <label tcenter>{{ $locale["object"]["incentive"]["tip-mount-label"] }}</label>
          <input tcenter type="number" name="mount" placeholder="1" v-model="tipMount" @change="sgtkey++" />
        </div>
        <div class="block-input">
          <label>{{ $locale["object"]["incentive"]["tip-message-label"] }}</label>
          <TextareaRich name="message" :placeholder="$locale['object']['incentive']['tip-message-placeholder']" />
          <p class="desc">{{ $locale["object"]["incentive"]["optional-label"] }}</p>
        </div>
        <div class="block-input">
          <PrimaryButton _class="block send-tip-btn" mauto @click="sendTip">
            <span v-if="$lockapp"><iconic name="gspinner" /></span>
            <span v-else>
              {{ $locale["object"]["incentive"]["send-tip-btn"] }} / {{ tipMount }}
              <small>{{ $locale["credits"] }}</small>
            </span>
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextareaRich from "../widgets/TextareaRich.vue";
import PrimaryButton from "../buttons/PrimaryButton.vue";
import IncentivesTipSent from "./IncentivesTipSent.vue";
import SubscribePriceBy from "../buy/SubscribePriceBy.vue";
export default {
  components: { TextareaRich, PrimaryButton, PrimaryButton, IncentivesTipSent, SubscribePriceBy },
  data: function() {
    return {
      status: null,
      creator: this.$store.state.incentive,
      tipMount: 1,
      sgtkey: 0,
    };
  },
  methods: {
    isLoading: function(state) {
      this.$store.state.lockapp = state;
    },
    sendTip: function() {
      this.isLoading(true);
      const messagebox = document.querySelector(`.new-tip [name="message"]`);
      const mountbox = document.querySelector(`.new-tip [name="mount"]`);
      const data = {
        mount: mountbox.value,
        creator: this.creator?.user?._id,
        message: messagebox.innerHTML.trim(),
      };
      const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "incentives/send_tip", data);
      sedData.then((response) => this.sendTipSuccess(response));
      sedData.catch((response) => this.sendTipError(response));
    },
    sendTipError: function(response) {
      this.isLoading(false);
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
    sendTipSuccess: function(response) {
      this.isLoading(false);
      if (response?.data?.success) {
        this.status = "success";
        this.$store.dispatch("getProfile");
      } else if (response?.data?.reason === 1) {
        this.$emit("notFounds");
      } else {
        this.ialert.go({ message: response?.data?.message, unique: true, title: "Error" });
      }
    },
  },
};
</script>

<style lang="scss">
.new-tip {
  .block-input {
    text-align: center;
    input {
      &[name="mount"] {
        text-align: center;
      }
      &[type="number"] {
        font-weight: bold;
      }
    }
    .desc {
      font-size: 80%;
      margin: $mpadding/3 0 0 0;
      opacity: 0.8;
    }
  }
  .tip-suggestions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $mpadding;
    label.price-by {
      padding: 0;
    }
    .price-by-check {
      display: none;
    }
    .price-by-content {
      padding: 0;
      justify-content: center;
      border-radius: 50%;
      @include fixedSize($mpadding * 4);
      margin: auto;
    }
    input[name="sgtip"] + .price-by-content {
      background-color: $primary_color;
      box-shadow: $primary_shadow;
      * {
        color: #fff;
      }
    }
    input[name="sgtip"]:checked + .price-by-content {
      background-color: #fff;
      * {
        color: $primary_color;
      }
    }
  }
}
</style>
