<template>
  <div v-if="hasLanguage">
    <Modal class="incentive" ref="modal" @close="menuClose" v-if="modal === 'menu'" :settings="settings">
      <ModalItem :label="$locale['object']['incentive']['send-tip-btn']" @click="modal = 'tip'" />
      <ModalItem :label="$locale['object']['incentive']['send-flowers-btn']" @click="modal = 'flowers'" />
      <ModalItem :label="$locale['object']['incentive']['send-gift-btn']" @click="modal = 'gift'" />
      <ModalItem :label="$locale['object']['incentive']['send-message-btn']" @click="goToMessages" />
    </Modal>
    <Modal class="incentive" ref="modal" @close="menuClose" v-else-if="modal === 'tip'" :settings="settings">
      <div slot="header" v-gap="['', '', '', 'calc(var(--mpadding))']" v-size="'130%'" dcolor><iconic name="mf_lock" /></div>
      <IncentivesTip @close="modalClose" @notFounds="notFounds" />
    </Modal>
    <Modal class="incentive" ref="modal" @close="menuClose" v-else-if="modal === 'flowers'" :settings="settings">
      <div slot="header" v-gap="['', '', '', 'calc(var(--mpadding))']" v-size="'130%'" dcolor><iconic name="mf_lock" /></div>
      <IncentivesFlowers @close="modalClose" @notFounds="notFounds" />
    </Modal>
    <Modal class="incentive" ref="modal" @close="menuClose" v-else-if="modal === 'gift'" :settings="settings">
      <div slot="header" v-gap="['', '', '', 'calc(var(--mpadding))']" v-size="'130%'" dcolor><iconic name="mf_lock" /></div>
      <IncentivesGift @close="modalClose" @notFounds="notFounds" />
    </Modal>
    <Modal class="incentive" ref="modal" @close="menuClose" v-else-if="modal === 'credits'" :settings="settings">
      <div slot="header" v-gap="['', '', '', 'calc(var(--mpadding))']" v-size="'130%'" dcolor><iconic name="mf_lock" /></div>
      <SubscribeNotFound @credits="getCredits" />
    </Modal>
  </div>
</template>

<script>
import Modal from "../widgets/Modal.vue";
import ModalItem from "../widgets/ModalItem.vue";
import IncentivesTip from "./IncentivesTip.vue";
import IncentivesGift from "./IncentivesGift.vue";
import IncentivesFlowers from "./IncentivesFlowers.vue";
import SubscribeNotFound from "../buy/SubscribeNotFound.vue";
export default {
  components: { Modal, ModalItem, IncentivesTip, IncentivesGift, IncentivesFlowers, SubscribeNotFound },
  data: function() {
    return {
      modal: "menu",
      settings: {
        class: "view menu bottom mk_head",
        animation: "fadeIn",
      },
    };
  },
  methods: {
    menuClose: function() {
      this.$store.state.incentive = false;
      this.modal = "menu";
    },
    modalClose: function() {
      this.$refs.modal.close();
    },
    notFounds: function() {
      this.modal = "credits";
    },
    getCredits: function() {
      this.modalClose();
      this.goToCredits();
    },
    goToMessages: function() {
      this.userMessages(this.$store.state.incentive);
      this.menuClose();
      this.modalClose();
    },
  },
  computed: {
    hasLanguage: function() {
      return this.$locale && this.$locale["object"] && this.$locale["object"]["incentive"];
    },
  },
  watch: {
    "$store.state.incentive": function(state) {
      if (state) {
        this.modal = state.modal;
        this.$refs.modal.open();
      }
    },
  },
};
</script>
