<template>
  <div class="new-flowers">
    <IncentivesFlowersSent v-if="status === 'success'" :autoclose="3000" @autoclosed="$emit('close')" />
    <div v-else>
      <div class="block-input" tcenter bold>
        <span>{{ $locale["object"]["incentive"]["send-flowers-title"] }}</span>
        <span v-html="` @${creator.user.user}`"></span>
      </div>
      <div class="products-loading" v-if="loading && !$lockapp" tcenter dcolor padding-top><iconic name="gspinner" /></div>
      <div v-else class="block-products">
        <Collapse ref="products" header="hidden">
          <SubscribePriceBy
            v-for="(product, index) in products"
            :key="`f${index}`"
            name="flower"
            :label="product.name"
            :value="product._id"
            :price="`${product.price} ${$locale['object']['user-profile']['credits-label']}`"
            :subscription="{ status: 'on' }"
            :checked="index === 1"
            :image="productCover(product)"
            @checked="selectProduct(product)"
          />
        </Collapse>
      </div>
      <div class="IncentivesFlowersOptions" dpadding>
        <div class="block-input">
          <label>{{ $locale["object"]["incentive"]["tip-message-label"] }}</label>
          <TextareaRich name="message" :placeholder="$locale['object']['incentive']['tip-message-placeholder']" />
          <p class="desc">{{ $locale["object"]["incentive"]["optional-label"] }}</p>
        </div>
        <div :class="`block-input${loading ? ' noevents' : ''}`">
          <PrimaryButton _class="block send-tip-btn" mauto @click="sendFlowers">
            <span v-if="$lockapp || loading"><iconic name="gspinner" /></span>
            <span v-else>
              {{ $locale["object"]["incentive"]["send-flowers-btn"] }} / {{ product.price }} {{ $locale["object"]["user-profile"]["credits-label"] }}
            </span>
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncentivesFlowersSent from "./IncentivesFlowersSent.vue";
import TextareaRich from "../widgets/TextareaRich.vue";
import SubscribePriceBy from "../buy/SubscribePriceBy.vue";
import PrimaryButton from "../buttons/PrimaryButton.vue";
import Collapse from "../widgets/Collapse.vue";
export default {
  components: { IncentivesFlowersSent, TextareaRich, SubscribePriceBy, PrimaryButton, Collapse },
  data: function() {
    return {
      loading: false,
      status: null,
      creator: this.$store.state.incentive,
      products: {},
      product: {},
    };
  },
  methods: {
    isLoading: function(state) {
      this.loading = state;
      this.$store.state.lockapp = state;
    },
    selectProduct: function(product) {
      this.product = product;
    },
    productCover: function(product) {
      if (product?.media) {
        return product?.media[0]?.src;
      }
    },
    getProducts: async function() {
      this.loading = true;
      this.sort = encodeURI(JSON.stringify({ type: -1, price: 1 }));
      this.path = `products?type=flowers&sort=${this.sort}`;
      const sedData = this.$api.get(process.env.VUE_APP_API_HOST + this.path);
      sedData.then((response) => {
        this.products = response.data.items;
        this.isLoading(false);
        setTimeout(() => {
          this.selectProduct(this.products[1]);
          this.$refs.products.open();
        });
      });
    },
    sendFlowers: function() {
      this.isLoading(true);
      const messagebox = document.querySelector(`.new-flowers [name="message"]`);
      const flowerbox = document.querySelector(`.new-flowers [name="flower"]:checked`);
      const data = {
        flower: flowerbox.value,
        creator: this.creator?.user?._id,
        message: messagebox.innerHTML.trim(),
      };
      const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "incentives/send_flowers", data);
      sedData.then((response) => this.sendFlowersSuccess(response));
      sedData.catch((response) => this.sendFlowersError(response));
    },
    sendFlowersError: function(response) {
      this.isLoading(false);
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
    sendFlowersSuccess: function(response) {
      this.isLoading(false);
      if (response?.data?.success) {
        this.status = "success";
        this.$store.dispatch("getProfile");
      } else if (response?.data?.reason === 1) {
        this.$emit("notFounds");
      } else {
        this.ialert.go({ message: response?.data?.message, unique: true, title: "Error" });
      }
    },
  },
  mounted: async function() {
    await this.getProducts();
  },
};
</script>

<style lang="scss" scoped>
.new-flowers {
  .block-input {
    text-align: center;
    margin: $mpadding 0 0 0;
    input {
      text-align: center;
      &[type="number"] {
        font-weight: bold;
      }
    }
    .desc {
      font-size: 80%;
      margin: $mpadding/3 0 0 0;
      opacity: 0.8;
    }
  }
  .products-loading {
    min-height: 35px;
  }
  .block-products {
    margin: $mpadding 0 0 0;
    border-top: solid 1px $alto;
    .price-by {
      border-bottom: solid 1px $alto;
    }
  }
}
</style>
